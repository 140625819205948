export const adultOptions = Array(5)
  .fill(0)
  .map((_, index) => ({
    label: `${index + 1}`,
    value: index + 1,
  }));

export const childOptions = Array(5)
  .fill(0)
  .map((_, index) => ({
    label: `${index}`,
    value: index,
  }));

export const childAgeOptions = [
  {
    label: '',
    value: '',
  },
  ...Array(18)
    .fill(0)
    .map((_, index) => ({
      label: `${index}`,
      value: index,
    })),
];
